import React from 'react';
import './Header.css';

class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="logo">
          {/* Your logo here */}
        </div>
        <nav className="nav">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/career-quiz">Career Quiz</a></li>
            <li><a href="/career-exploration">Career Exploration</a></li>
            <li><a href="https://www.instagram.com/career.madad/" target="_blank">Instagram</a></li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;
